import request from '../utils/clientRequest.js';

export default {
	getProductList: function(param) {
		return request({
			url: 'product/list',
			method: 'post',
			data:param
		})
	}
};
