<template>
	<div class="flex-container">
		<div class="main">
			<div class="para-info">
				<b></b>
				<span>
					产品中心
					<p>Product Center</p>
				</span>
				<div class="selected" @click="$router.push('./')">返回首页</div>

			</div>
			<div class="product-list">

				<div v-for="item in productList" class="product-list-item" :key="item.id"
					@click="$router.push('/product-detail?id='+item.id)">
					<el-image class="product-image" fit="contain" lazy
						:src="global.getImage(item.prevImage,2,338,209) ">
					</el-image>
					<div class="product-name">
						<span>
							{{item.name}}
						</span>
					</div>
				</div>

			</div>
			<el-pagination background layout="prev, pager, next" :current-page="currentPage" :total="totalItems"
				:page-size="pageSize" @current-change="handleCurrentPageChanged">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import api from "@/api/product";

	export default {
		data: function() {
			return {
				productList: [],
				totalItems: 0,
				currentPage: 1,
				pageSize: 6
			};
		},
		mounted() {
			this.queryProduct();
		},
		methods: {
			handleCurrentPageChanged(page) {
				this.currentPage = page;
				this.queryProduct();
			},
			queryProduct() {
				var self = this;
				var pageParam = {
					pagination: {
						page: this.currentPage,
						itemsPerPage: this.pageSize
					}
				};
				api.getProductList(pageParam).then(function(resp) {
					if (resp.success) {
						self.productList = resp.data.items;
						self.totalItems = resp.data.totalItems;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped="true">
	.flex-container {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 40px;

		.main {
			width: 1100px;
		}


		.para-info {
			display: flex;
			width: 1100px;
			align-items: center;

			margin-bottom: 40px;

			b {
				width: 1em;
				height: .2em;
				display: block;
				background-color: $primary-red;
				margin-right: 1em;
			}

			span {
				flex-grow: 1;
				text-align: left;
				font-size: 1.5em;
				color: $primary-font-color-deep;

				p {
					margin: 0;
					font-size: .8em;
					color: $primary-font-color-light;
				}
			}

			div {
				width: 7em;
				padding: 5px;
				border-radius: 2em;
				color: $primary-font-color-light;
				border: 1px solid $primary-font-color-light;
				margin-left: 1.5em;
				cursor: pointer;
				text-align: center;
			}

			.selected {
				background-color: $primary-red;
				color: #fff;
				border: 1px solid $primary-red;
			}

			.selected:hover {
				text-decoration: underline;
			}
		}

		.product-list {
			display: flex;
			width: 1140px;
			flex-wrap: wrap;
			margin-left: -40px;

			.product-list-item {
				cursor: pointer;
				margin-left: 40px;
				border: 1px solid $primary-font-color-disabled;
				width: 338px;

				.product-image {
					width: 338px;
					height: 209px;
				}

				margin-bottom: 40px;

				.product-name {
					padding: 1em;
					background: #fff;

					span {
						text-overflow: -o-ellipsis-lastline;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						line-clamp: 2;
						-webkit-box-orient: vertical;
					}

				}
			}

			.product-list-item:hover {
				opacity: .5;
			}
		}
	}

	::v-deep .active {
		background-color: $primary-red !important;
	}

	::v-deep .number:hover {
		color: $primary-red !important;
	}

	::v-deep .active:hover {
		color: #fff !important;
	}

	::v-deep .el-icon-d-arrow-right {
		color: $primary-red !important;
	}
</style>
